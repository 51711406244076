<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card v-if="mountedActionsComplete">
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <b-row sm="12">
                            <b-col sm="4">
                                <b-form-group label="Instalación Master" label-for="vhe-code">
                                    <v-select
                                        v-model="selectedInstallation"
                                        class="pt-0" label="installation_code" name="installation_code"
                                        :reduce="(option) => option"
                                        :options="masterInstallations"
                                        :disabled="edit"
                                        @input="setInstallation"
                                    >
                                    <template #no-options="{ }">
                                       -
                                    </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>

<!--                            <b-col v-if="!edit" sm="2">
                                <segment-id-select :segment-id="child_installation.segment_id" :disabled="true"
                                                   :validation="validation_child_installation.segment_id"
                                />
                            </b-col>-->
                            <b-col v-if="!edit" sm="2">
                                <installation-type-id-select
                                    :installation-type-id="child_installation.installation_type_id"
                                    :validation="validation_child_installation.installation_type_id" @update="handleUpdateFields"/>
                            </b-col>
                            <b-col v-if="!edit" sm="2">
                                <b-form-group label="Instalaciones a añadir" label-for="installation_quantity">
                                    <b-form-input v-model="child_installation.quantity" autocomplete="off" type="number" max="50"/>
                                    <small v-if="validation_child_installation.quantity" class="text-danger">
                                        {{ validation_child_installation.quantity }}</small>
                                </b-form-group>
                            </b-col>
                            <b-col v-if="edit" sm="4">
                                <b-form-group label="Instalación Hija" label-for="vhe-code">
                                    <b-form-input v-model="child_installation.vhe_code" autocomplete="off" />
                                </b-form-group>
                            </b-col>
<!--
                            <b-col sm="4">
                                <typology-id-select :typology-id="child_installation.typology_id"  :validation="validation_child_installation.typology_id"
                                                    @update="handleUpdateFields"/>
                            </b-col>
-->

                            <b-col v-if="!edit" class="mr-1 ml-1 mt-2">
                                <b-row sm="12" >
                                    <b-button v-if="!edit && can('create_vhe_children')" variant="outline-success" block @click="createInstallation()">
                                        Crear
                                    </b-button>
                                    <b-button v-if="edit && can('edit_vhe_children')" variant="outline-success" block @click="updateInstallation()">
                                        Guardar
                                    </b-button>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-tabs>

                            <b-tab v-if="edit" active title="Datos Instalación">
                                <main-form/>
                            </b-tab>
                            <b-tab v-if="edit" title="Mantenimientos Correctivos">
                                <main-form-document-maintenance/>
                            </b-tab>

                        </b-tabs>
                    </b-overlay>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {BTab, BCard, BCol, BTabs, BOverlay, BRow, BFormGroup, BButton, BFormInput} from 'bootstrap-vue'
import {mapState} from "vuex"

import {mapFields} from "vuex-map-fields"

import mainForm from "@/views/vhe/children_installations/form/tabInstallationData/formView"
import SegmentIdSelect from "@/views/vhe/masters_installations/form/formHeader/components/segmentIdSelect"
import installationTypeIdSelect
    from "@/views/vhe/masters_installations/form/formInstallationData/components/installationTypeIdSelect"
import mainFormDocumentMaintenance
    from "@/views/vhe/children_installations/form/tabMaintenance/mainFormDocumentMaintenance"
import helper from "@/resources/helpers/helpers"
import vSelect from "vue-select"
import {
    VHE_CHILDREN_INSTALLATIONS_LIST,
} from "@/router/web/constants"

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BOverlay,
        BTab,
        BTabs,
        BFormGroup,
        BButton,
        BFormInput,
        vSelect,

        mainForm,
    //    SegmentIdSelect,
        installationTypeIdSelect,
        mainFormDocumentMaintenance,
    },
    data() {
        return {
            edit: false,
            showLoading: false,
            mountedActionsComplete: false,
            segmentsList: [],
            masterInstallations: [],
            selectedInstallation: null,
        }
    },
    computed: {
        ...mapState('childrenInstallationVhe', ['validation_child_installation']),
        ...mapState('masterInstallationVhe', []),
        ...mapFields({
            child_installation: 'childrenInstallationVhe.child_installation',
            master_installation: 'masterInstallationVhe.master_installation',
        }),
    },
    async beforeMount() {
        this.$store.commit('childrenInstallationVhe/RESET_STATE')
        this.$store.commit('childrenInstallationVhe/CLEAR_VALIDATION')
        this.getMasterInstallations()
        await this.executeMountedActions()

        this.mountedActionsComplete = true
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        async executeMountedActions() {

            if (this.$route.params.id) {
                this.edit = true
                await this.getChildInstallation()
            } else {
                this.edit = false
            }
            this.$root.$refs.childrenInstallationForm = this
        },
        async getChildInstallation() {
            this.showLoading = true
            this.$store.dispatch('childrenInstallationVhe/getChildInstallation', {
                relations: ['masterInstallation', 'documentMaintenanceChildren.inspection',
                    'documentMaintenanceChildren.insitu','documentMaintenanceChildren.invigilando',
                    'documentMaintenanceChildren.incidences'],
                id: this.$route.params.id ?? this.child_installation.id,
            })
            .then(response => {
                this.selectedInstallation = response.master_installation
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        cancel() {
            //   this.$router.push(VHE_MASTERS_INSTALLATIONS_LIST)
        },
        createInstallation() {

            this.child_installation['inst_mast_num_cog_vhe'] = this.inst_mast_num_cog_vhe

            this.$store.dispatch('childrenInstallationVhe/createChildrenInstallation', this.child_installation)
            .then(response => {
                this.child_installation.id = response.id

                helper.showToast('Instalación hija creada correctamente!', 'CheckIcon', 'success')
                this.$router.replace(VHE_CHILDREN_INSTALLATIONS_LIST)
            })
            .catch(error => {
                helper.showToast('Error al crear instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        updateInstallation() {
            this.$store.dispatch('childrenInstallationVhe/updateChildrenInstallation', this.child_installation)
            .then(response => {
                helper.showToast('Instalación hija editada correctamente!', 'CheckIcon', 'success')
            })
            .catch(error => {
                helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        getMasterInstallations(){
            this.$store.dispatch('masterInstallationVhe/getMasterInstallationsList', {relations: ['segment']})
            .then(response => {
                this.masterInstallations = response

            })
            .catch(error => {
                helper.showToast('Error al obtener instalaciones masters', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        setInstallation() {
            if (this.selectedInstallation ) {
                this.child_installation.master_installation_id = this.selectedInstallation.id
                this.child_installation.segment_id = this.selectedInstallation.segment_id
                this.child_installation.segment_code = this.selectedInstallation.segment.code
            } else {
                this.child_installation.segment_id = null
                this.child_installation.master_installation_id = null
                this.child_installation.segment_code = null
            }
        },
        handleUpdateFields(updatedFields) {

            this.child_installation = {...this.child_installation, ...updatedFields}
        },

    },
}
</script>
