<template>
    <div>
        <b-form-group label-for="dias" class="mt-2 ml-5">
            <b-form-group class="form-check form-check-inline">
                <b-form-checkbox v-model="in_service" :value="1" :disabled="disabled">
                    En Servicio
                </b-form-checkbox>
            </b-form-group>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormCheckbox,
    },
    props: {
        in_service: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localInService: this.in_service,
        }
    },
    watch: {
        draft(newVal) {
            this.localInService = newVal
            this.$emit('update', { in_service: this.localInService })
        },
    },
}
</script>
