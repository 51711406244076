<template>
    <div>
        <b-card border-variant="primary" class="p-0">
            <b-row sm="12">
                <b-col sm="2">
                    <installation-active-checkbox :active_installation="child_installation.active_installation"
                                                  @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="2">
                    <draft-checkbox :draft="child_installation.draft" :disabled="false" @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="2">
                    <installation-replaced-checkbox :replaced_installation="child_installation.replaced_installation"
                                                    @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="2">
                    <installation-retired-checkbox :retired_installation="child_installation.retired_installation"
                                                   @update="handleUpdateFields"/>
                </b-col>
                <!--                <b-col sm="3">
                                    <company-input :company-id="master_installation.company_id" :disabled="isDisabled" />
                                </b-col>-->
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row sm="12">
                <b-col sm="4">
                    <client-input :client="master_installation.client" :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <installation-type-id-select :installation-type-id="child_installation.installation_type_id"
                                                 :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <serial-number :serial-number="child_installation.serial_number" :disabled="false"
                                   @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="2">
                    <b-form-group label="Valor Asegurado" label-for="money">
                        <money v-model="child_installation.insured_value" class="form-control" :disabled="false"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row sm="12">
                <b-col sm="2">
                    <date-start-flat-pickr :date-start="child_installation.start_date"
                                           :validation="validation_child_installation.start_date"
                                           @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="2">
                    <date-end-flat-pickr :date-end="child_installation.end_date"
                                         :validation="validation_child_installation.end_date"
                                         @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="1">
                    <date-closing-flat-pickr :date-closing="child_installation.closing_date"
                                             :validation="validation_child_installation.closing_date"
                                             @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="2">
                    <period-description-input :period-description="child_installation.period_description"
                                              :disabled="isDisabled"
                                              :validation="validation_child_installation.period_description"
                                              @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="3 ">
                    <responsible-id-select :responsible-id="master_installation.responsible_id" :disabled="isDisabled"
                                           @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="2">
                    <project-responsible-id-select :project-responsible-id="master_installation.project_responsible_id"
                                                   :disabled="isDisabled"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row xl="12">
                <b-col xl="2">
                    <level1-id-select :level1id="child_installation.level_1_id" :disabled="isDisabled"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <level2-id-select :level2id="child_installation.level_2_id" :disabled="isDisabled"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <level3-id-select :level3id="child_installation.level_3_id" :disabled="isDisabled"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <level4-id-select :level4id="child_installation.level_4_id" :disabled="isDisabled"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="3">
                    <or-input :or="master_installation.or_number" :disabled="true" @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row cols="12">
                <b-col sm="3">
                    <client-type-id-input :client-type-id="master_installation.client_type_id" :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <city-input :city="master_installation.city" :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <country-id-select :country-id="master_installation.country_id" :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <postal-code-input :postal-code="master_installation.postal_code" :disabled="isDisabled"/>
                </b-col>
            </b-row>
            <b-row cols="12">
                <b-col sm="3">
                    <province-id-select :province-id="master_installation.province_id"
                                        :country-id="master_installation.country_id" :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <latitude-input :latitude="master_installation.latitude" :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <longitude-input :longitude="master_installation.longitude" :disabled="isDisabled"/>
                </b-col>
                <b-col sm="3">
                    <figure-id-select :figure-id="master_installation.figure_id" :disabled="isDisabled"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <address-input :address="master_installation.address" :disabled="isDisabled"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row cols="12">
                <b-col sm="12">
                    <observations-text-area :observations="master_installation.observations" :disabled="isDisabled"/>
                </b-col>
            </b-row>
            <!--            <b-row>
                            <b-col sm="12">
                                <n-ord-generic-input :n-order-generic="child_installation.n_order_generic"/>
                            </b-col>
                        </b-row>-->
        </b-card>
        <b-col class="d-flex justify-content-end">
            <b-button v-if="!edit && can('edit_vhe_children')" class="col-sm-2" variant="outline-success" block
                      @click="createInstallation()">
                Crear
            </b-button>
            <b-button v-if="edit  && can('create_vhe_children')" class="col-sm-2" variant="outline-success" block
                      @click="updateInstallation()">
                Guardar
            </b-button>
        </b-col>
    </div>
</template>

<script>
import {BCard, BCol, BRow, BButton, BFormGroup} from 'bootstrap-vue'
import {mapState} from "vuex"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
//components
import installationActiveCheckbox
    from "@/views/vhe/children_installations/form/tabInstallationData/components/installationActiveCheckbox"
import installationReplacedCheckbox
    from "@/views/vhe/children_installations/form/tabInstallationData/components/installationReplacedCheckbox"
import installationRetiredCheckbox
    from "@/views/vhe/children_installations/form/tabInstallationData/components/installationRetiredCheckbox"
import {mapFields} from "vuex-map-fields"

import draftCheckbox from "@/views/vhe/masters_installations/form/formInstallationData/components/draftCheckbox"
import serialNumber from "@/views/vhe/masters_installations/form/formInstallationData/components/serialNumber"
import companyInput from "@/views/vhe/masters_installations/form/formInstallationData/components/companyInput"
import clientInput from "@/views/vhe/masters_installations/form/formInstallationData/components/clientInput"
import installationTypeIdSelect
    from "@/views/vhe/masters_installations/form/formInstallationData/components/installationTypeIdSelect"
import insuredValueInput
    from "@/views/vhe/masters_installations/form/formInstallationData/components/insuredValueInput"
import dateEndFlatPickr from "@/views/vhe/masters_installations/form/formInstallationData/components/dateEndFlatPickr"
import dateClosingFlatPickr
    from "@/views/vhe/masters_installations/form/formInstallationData/components/dateClosingFlatPickr"
import dateStartFlatPickr
    from "@/views/vhe/masters_installations/form/formInstallationData/components/dateStartFlatPickr"
import periodDescriptionInput
    from "@/views/vhe/masters_installations/form/formInstallationData/components/periodDescriptionInput"
import level2IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level2IdSelect"
import responsibleIdSelect
    from "@/views/vhe/masters_installations/form/formInstallationData/components/responsibleIdSelect"
import clientTypeIdInput
    from "@/views/vhe/masters_installations/form/formInstallationData/components/clientTypeIdInput"
import cityInput from "@/views/vhe/masters_installations/form/formInstallationData/components/cityInput"
import countryIdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/countryIdSelect"
import postalCodeInput from "@/views/vhe/masters_installations/form/formInstallationData/components/postalCodeInput"
import provinceIdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/provinceIdSelect"
import latitudeInput from "@/views/vhe/masters_installations/form/formInstallationData/components/latitudeInput"
import longitudeInput from "@/views/vhe/masters_installations/form/formInstallationData/components/longitudeInput"
import figureIdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/figureIdSelect"
import projectResponsibleIdSelect
    from "@/views/vhe/masters_installations/form/formInstallationData/components/projectResponsibleIdSelect"
import addressInput from "@/views/vhe/masters_installations/form/formInstallationData/components/addressInput"
import observationsTextArea
    from "@/views/vhe/masters_installations/form/formInstallationData/components/observationsTextArea"
import nOrdGenericInput from "@/views/vhe/children_installations/form/tabInstallationData/components/nOrdGenericInput"
import helper from "@/resources/helpers/helpers"
import InServiceCheckbox from "@/views/vhe/masters_installations/form/formInstallationData/components/inServiceCheckbox"
import level1IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level1IdSelect"
import level3IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level3IdSelect"
import level4IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level4IdSelect"
import orInput from "@/views/vhe/masters_installations/form/formInstallationData/components/orInput"


export default {
    name: "FormView",
    components: {
        BCol,
        BCard,
        BRow,
        BButton,
        BFormGroup,

        //componentsForm
        installationActiveCheckbox,
        installationReplacedCheckbox,
        installationRetiredCheckbox,
        draftCheckbox,
        serialNumber,
        //companyInput,
        clientInput,
        installationTypeIdSelect,
        dateStartFlatPickr,
        dateEndFlatPickr,
        dateClosingFlatPickr,
        periodDescriptionInput,
        level1IdSelect,
        level2IdSelect,
        level3IdSelect,
        level4IdSelect,
        responsibleIdSelect,
        clientTypeIdInput,
        cityInput,
        countryIdSelect,
        postalCodeInput,
        provinceIdSelect,
        latitudeInput,
        longitudeInput,
        figureIdSelect,
        projectResponsibleIdSelect,
        addressInput,
        observationsTextArea,
        orInput
        // nOrdGenericInput
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
            isDisabled: true,
        }
    },
    computed: {
        ...mapState('childrenInstallationVhe', ['validation_child_installation']),
        ...mapFields({
            child_installation: 'childrenInstallationVhe.child_installation',
            master_installation: 'masterInstallationVhe.master_installation',
        }),

    },
    created() {
        if (this.$route.params.id) {
            this.edit = true
        } else {
            this.edit = false
        }
        this.$root.$refs.formInstallationData = this
        // this.$root.$refs.masterInstallationForm = this
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        handleUpdateFields(updatedFields) {
            this.child_installation = {...this.child_installation, ...updatedFields}
        },
        createInstallation() {
            this.$store.dispatch('childrenInstallationVhe/createChildrenInstallation', this.master_installation)
            .then(response => {
                this.master_installation.id = response.id
                helper.showToast('Instalación hija creada correctamente!', 'CheckIcon', 'success')
                this.$root.$refs.masterInstallationForm.getMasterInstallation()
            })
            .catch(error => {
                helper.showToast('Error al crear instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        updateInstallation() {
            this.$store.dispatch('childrenInstallationVhe/updateChildrenInstallation', this.child_installation)
            .then(response => {
                helper.showToast('Instalación hija editada correctamente!', 'CheckIcon', 'success')
            })
            .catch(error => {
                helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>