<template>
    <div>
        <b-form-group label="Valor Asegurado" label-for="money">
            <money v-model="insuredValue2" class="form-control" :disabled="disabled"
                   :class="validation ? 'is-invalid': ''"/>
            <small v-if="validation" class="text-danger">
                {{ validation }}
            </small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
    },
    props: {
        insuredValue: {
            default: '0'
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        insuredValue2: {
            get() {
                return this.insuredValue
            },
            set(value) {
                this.$emit('update:insuredValue', value)
            }
        },
    },
}
</script>
