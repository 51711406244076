<template>
    <div>
        <b-card border-variant="primary" class="p-0">
            <h5 class="mb-0">Configuración Comunicaciones</h5>
            <b-row sm="12">
                <b-col sm="2">
                    <b-form-group label-for="dias" class="mt-2">
                        <b-form-group class="form-check form-check-inline">
                            <b-form-checkbox v-model="child_installation.mant_sol_report" :value="1">
                                Se solicita informe
                            </b-form-checkbox>
                        </b-form-group>
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label-for="dias" class="mt-2">
                        <b-form-group class="form-check form-check-inline">
                            <b-form-checkbox v-model="child_installation.mant_sol_watch" :value="1">
                               Se solicita InVigilando
                            </b-form-checkbox>
                        </b-form-group>
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label-for="dias" class="mt-2">
                        <b-form-group class="form-check form-check-inline">
                            <b-form-checkbox v-model="child_installation.mant_sol_acae_situation" :value="1">
                                Se solicita Acae InSitu
                            </b-form-checkbox>
                        </b-form-group>
                    </b-form-group>
                </b-col>

                <b-col sm="2" class="mt-2">
                <b-col class="d-flex justify-content-end">
                    <b-button v-if="can('create_vhe_children_maintenance')" variant="outline-success" block @click="updateInstallation()">
                        Guardar
                    </b-button>
                </b-col>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {BFormGroup, BFormCheckbox, BCard, BCol, BRow, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {mapFields} from "vuex-map-fields"

export default {
    name: "FormView",
    components: {
        BCol,
        BCard,
        BRow,
        BFormGroup,
        BFormCheckbox,
        BButton
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
            isDisabled: true,
        }
    },
    computed: {
        ...mapState('childrenInstallationVhe', []),
        ...mapState('childrenInstallationDocumentsVhe', []),
        ...mapFields({
            child_installation: 'childrenInstallationVhe.child_installation',
        }),
    },
    methods:{
        can(permission) {
            return this.$store.getters.can(permission)
        },
        updateInstallation(){
            this.$root.$refs.childrenInstallationForm.updateInstallation()
        }
    }
}
</script>