<template>
  <div>
    <b-form-group label-for="dias" class="mt-2">
      <b-form-group class="form-check form-check-inline">
        <b-form-checkbox v-model="retired_installation" :value="1" :disabled="disabled">
          Retirado
        </b-form-checkbox>
      </b-form-group>
    </b-form-group>
  </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    retired_installation: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localRetired: this.retired_installation,
    }
  },
  watch: {
    retired_installation(newVal) {
      this.localRetired = newVal
      this.$emit('update', { retired_installation: this.localRetired })
    },
  },
}
</script>
