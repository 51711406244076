<template>
    <div>
        <form-maintenance/>
        <form-inspection/>
        <form-invigilando/>
        <form-in-situ/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import formMaintenance from "@/views/vhe/children_installations/form/tabMaintenance/components/formMaintenance"
import formInspection from "@/views/vhe/children_installations/form/tabMaintenance/components/formInspection"
import formInvigilando from "@/views/vhe/children_installations/form/tabMaintenance/components/formInvigilando"
import formInSitu from "@/views/vhe/children_installations/form/tabMaintenance/components/formInSitu"

const {mapFields} = createHelpers({
    getterType: 'childrenInstallationDocumentsVhe/getField',
    mutationType: 'childrenInstallationDocumentsVhe/updateField',
})

export default {
    name: "FormGeneralDataAndRisksView",
    components: {
        formMaintenance,
        formInspection,
        formInvigilando,
        formInSitu,
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
        }
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>