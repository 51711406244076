<template>
  <div>
    <b-form-group label-for="dias" class="mt-2">
      <b-form-group class="form-check form-check-inline">
        <b-form-checkbox v-model="replaced_installation" :value="1" :disabled="disabled">
            Substituido
        </b-form-checkbox>
      </b-form-group>
    </b-form-group>
  </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    replaced_installation: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localReplaced: this.replaced_installation,
    }
  },
  watch: {
    replaced_installation(newVal) {
      this.localReplaced = newVal
      this.$emit('update', { replaced_installation: this.localReplaced })
    },
  },
}
</script>
